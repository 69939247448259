export default {
  "prices1": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was kostet ein Eintrag im ADB?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Für Mitglieder des Börsenvereins des Deutschen Buchhandels und fachverwandten Verbänden: <br> - Arbeitsgemeinschaft Wissenschaftlicher Sortiments- und Fachbuchhandlungen<br>   - Buch.netz - Fachgemeinschaft Versandbuchhandel im bevh <br> - Bundesverband Deutscher Buch Zeitungs- und Zeitschriften-Grossisten e.V.<br>     - Bundesverband Digitalpublisher und Zeitungsverleger e.V.<br>     - Bundesverband der Medien- und Dienstleistungshändler e.V.<br>     - Deutscher Musikverleger-Verband e.V.<br>     - Gesamtverband Deutscher Musikfachgeschäfte e.V.<br> - KM. katholischermedienverband e.V.<br>- Verband Bildungsmedien e.V.<br>     - Verband Deutscher Antiquare e.V.<br>     - Verband Deutscher Bahnhofsbuchhändler e.V.<br>     - Verband Deutscher Bühnen- und Medienverlage e.V.<br>     - Verband deutscher Lesezirkel e.V.<br>     - Vereinigung Evangelischer Buchhändler und Verleger e.V.<br>  sowie für Unternehmen <br>- in Österreich <br>- der Schweiz<br> - und Liechtenstein<br>ist der Eintrag <strong>kostenlos.</strong><br>Für Einträge von Nicht-Mitgliedern des Börsenvereins berechnet die MVB GmbH jährlich - unabhängig vom Einstellzeitpunkt oder der Einstelldauer - 18 Euro plus Mehrwertsteuer rückwirkend.<br>Rechnungen sind netto innerhalb von 30 Tagen ab Rechnungsdatum ohne jeden Abzug zahlbar. </p> "])}
  },
  "prices2": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie und wann bezahle ich meinen Eintrag?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Die Rechnungen werden zusammen mit dem VLB erstellt. Dies erfolgt in der Regel am letzten Tag eines Jahres. Bezahlt wird ein Eintrag immer rückwirkend für das abgelaufene Jahr. </p> "])}
  },
  "prices3": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt es auch Einträge, die nichts kosten?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Die gibt es. Dabei handelt es sich um Kurzeinträge. Sie enthalten lediglich den Namen, die ISBN sowie die Geschäftsart.</p> "])}
  },
  "prices4": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann ich kostenfrei im ADB recherchieren?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Ja, die Recherche ist immer kostenfrei. </p> "])}
  }
}