export default {
  "terms1": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1) Allgemein"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Das Adressbuch für den deutschsprachigen Buchhandel ist das unverzichtbare Anschriftenportal für die Buchhandelsbranche. Hier finden Sie Informationen zu mehr als 7.000 Buchhandlungen, 20.000 Verlagen und zahlreichen weiteren Selbstverlagen, dem Zwischenbuchhandel, weiteren Dienstleistern und den buchhändlerischen Organisationen in Deutschland, Österreich, der Schweiz und Liechtenstein. Das Adressbuch enthält außerdem das offizielle Verzeichnis der Internationalen Standard-Buchnummern (ISBN) der Bundesrepublik Deutschland und alle vom Börsenverein vergebenen Verkehrsnummern.</p>     <p class='mt-5'>Mit den Angaben zu ISBNs, Verkehrsnummern, DOIs GLNs, Umsatzsteuer-IDs, Liefer- und Fachzeitschriftenhinweisen sowie VLB-TIX und BAG-Teilnahmen liefert es umfassende Informationen für die Arbeit in der Buchhandelsbranche.</p>       <p class='mt-5'>Ein ADB-Eintrag liefert Branchenteilnehmern alle erforderlichen Informationen.  Erst mit Ihrem ADB-Eintrag wird das Auffinden Ihrer Adressdaten für Bestellungen und Kontaktaufnahmen wirklich einfach.  Sie verschaffen sich damit den optimalen Zugang zur Buchhandelsbranche.</p> "])}
  },
  "terms2": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2) Eintrag, Änderung oder Löschung"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Sie möchten mit Ihrem buchhändlerischen Unternehmen im Adressbuch für den deutschsprachigen Buchhandel vertreten sein, Anpassungen vornehmen oder wieder gelöscht werden?</p>    <p class='mt-5'>Dann kontaktieren Sie bitte unsere ADB-Redaktion direkt:</p>    <p class='mt-5'>Tel. +49 (0)69 1306-550<br>E-Mail: adressbuch[at]mvb-online.de <br>Ihr Antrag wird innerhalb einer Woche bearbeitet.</p>"])}
  },
  "terms3": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3) Daten"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Für die Erstbestückung Ihres Eintrags werden die Adress- und Kommunikationsdaten des ISBN-Antrags verwendet. Sie stimmen dieser Weitergabe durch die ISBN-Agentur für den Datenbestand im Adressbuch und deren Veröffentlichung im ADB zu. Alle Informationen dazu finden Sie im  <a class='underline' target='_blank' href='https://www.isbn-shop.de/dokumente/ISBN-Handbuch.pdf'>ISBN-Handbuch</a> </p> "])}
  },
  "terms4": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4) Kosten"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Für die Verwaltung und Aktualisierung Ihres Verlagseintrags berechnet die MVB GmbH jährlich, unabhängig vom Einstellzeitpunkt oder der Einstelldauer, 18 € zzgl. MwSt. rückwirkend im 4. Quartal eines Jahres.    Rechnungen sind innerhalb von 30 Tagen ab Rechnungsdatum ohne jeden Abzug zahlbar. </p>   <p class='mt-5'><a class='underline' target='_blank' href='https://www.boersenblatt.net/datenschutzinformationen'>Datenschutzinformationen von MVB für die Website boersenblatt.net </a></p>  <p class='mt-5'>Ich habe die Regeln für das ISBN-Handbuch und die Datenschutzinformationen zur Kenntnis genommen und     stimme den ADB-Nutzungsbedingungen zu. </p>    "])}
  }
}