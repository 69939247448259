import { createRouter, createWebHistory } from 'vue-router'





//Instead of importing the routes' components above, it's imported this way for lazy loading
const routes = [
    {path: '/', name: 'home', component: ()=> import('../../src/views/TheHome.vue')},
    {path: '/adbwas', redirect: '/'},
    {path: '/adbwas/control/cms/~webpage_id=adb6000', redirect: '/'},
    {path: '/adbwas/control/cms/~webpage_id=adb100;jsessionid=7C058BBAF6547574460B79332BFC4952.jvm1', redirect: '/'},
    {path: '/adbwas/control/cms/~webpage_id=adb6000', redirect: '/'},
    {path: '/adbwas/control/overviewAddressData', redirect: '/'},             
    {path: '/notFound', name: 'notFound', component: ()=> import('../../src/views/TheNotFound.vue')},
    {path: '/prices', redirect: '/'},
    {path: '/sidenav/faq', name: 'faq', component: ()=> import('../../src/views/TheFAQ.vue')},
    {path: '/sidenav/nutzungsbedingungen', name: 'nutzungsbedingungen', component: ()=> import('../../src/views/TheTermsOfUse.vue')},
    {path: '/terms', redirect: '/sidenav/nutzungsbedingungen'},
    {path: '/sidenav/impressum', name: 'imprint', component: ()=> import('../../src/views/TheImprint.vue')},
    {path: '/sidenav/datenschutz', name: 'datenschutz', component: ()=> import('../../src/views/ThePrivacy.vue')},
    {path: '/privacy', redirect: '/sidenav/datenschutz'},
    //{path: '/test', name: 'test', component: ()=> import('../../src/views/TheTest.vue')},
    {path: '/searchresult', name: 'searchresult', component: ()=> import('../../src/views/SearchResult.vue')},
    {path: '/party/:id', name: 'partySearch', component: ()=> import('../../src/views/TheParty.vue')},
    {path: '/party/mvbid=:mvbid', name: 'partyMVBSearch', component: ()=> import('../../src/views/TheParty.vue')},
    {path: '/party/:businessType/:id', name: 'partyPartlySearch1', component: ()=> import('../../src/views/TheParty.vue')},
    {path: '/party/:businessType/:companyName/:id', name: 'partyPartlySearch2', component: ()=> import('../../src/views/TheParty.vue')},
    {path: '/party/:businessType/:companyName/:companyNameAddendum/:id', name: 'partyPartlySearch3', component: ()=> import('../../src/views/TheParty.vue')},
    {path: '/party/:businessType/:companyName/:companyNameAddendum/:postalAddressCity/:id', name: 'partyPartlySearch4', component: ()=> import('../../src/views/TheParty.vue')},
    {path: '/party/:businessType/:companyName/:companyNameAddendum/:postalAddressCity/:postalAddressStreet/:id', name: 'party', component: ()=> import('../../src/views/TheParty.vue')},
    {path: '/party/:businessType/:companyName/:companyNameAddendum/:postalAddressCity/:postalAddressStreet/premium/:id', name: 'partyPremium', component: ()=> import('../../src/views/TheParty.vue')},
// Forward old URLS from Google Search with MVB ID
    {
      path: '/adbwas/control/showProfile/~partyId=:partyId(\\d+)',
      name: 'partyMVBIDSearch',
      component: () => import('../../src/views/TheParty.vue'),
      beforeEnter: (to, from, next) => {
        // get MVB ID (partyId) from url
        const match = to.path.match(/~partyId=(\d+)/);

        // if there is one
        if (match) {

          // extract partyId 
          const partyId = match[1];

          // check if party id is a number
          if (/^\d+$/.test(partyId)) {
              console.log("der router in "+partyId);
          
          // forward
              next('/party/mvbid=' + partyId);
            
           // next();
          } else {
            // go to homepage
            next('/');
          }
        } else {
          // go to homepage
          next('/');
        }
      },
    }, 
    { path: '/:catchAll(.*)', name: 'error404', component: ()=> import('../../src/views/The404Page.vue')}     
    
    
]



const router = createRouter({
    history: createWebHistory(),
    routes
})
export default router
