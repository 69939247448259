import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import i18n from './i18n'
import router from '@/router'
import './assets/tailwind.css'

import VueScrollTo from 'vue-scrollto'
import { plugin, defaultConfig } from '@formkit/vue'
import { de } from '@formkit/i18n'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faChevronDown, faChevronUp, faChevronLeft, faChevronRight, faEnvelope, faMagnifyingGlass, faPenToSquare, faPhone, faWindowClose, faFileLines, faBook, faAt, faLightbulb, faBinoculars, faEuroSign, faSpinner } from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(faEnvelope, faPenToSquare, faPhone, faWindowClose, faChevronDown, faChevronUp, faChevronLeft, faChevronRight, faMagnifyingGlass, faFileLines, faBook, faAt, faLightbulb, faBinoculars, faEuroSign, faSpinner)

const pinia = createPinia()

createApp(App)
.use(i18n)
.use(router)
.use(pinia)
.use(VueScrollTo)
.use(plugin,   
    defaultConfig({
    locales: { de },
    locale: 'de',
    //theme: 'genesis' // will load from CDN and inject into document head
  }))
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
