<script>
import { usePartyStore } from "../stores/PartyStore";
export default {
  data() {
    return {
      partyStore: null,
    };
  },
  computed: {
    party() {
      return this.partyStore.party;
    },
  },
  async created() {
    this.partyStore = usePartyStore();
  },
};
</script>

<template>
  <div class="container max-w-5xl mx-auto">
    <div class="md:grid grid-cols-2 justify-between w-auto ">
      <div class="w-full">
        <a href="/" class=""><img class="mt-4 mb-5" src="../assets/adbLogoNew.png" alt="AdB Logo" /></a>
      </div>
      <div><button class="md:float-right px-3 py-2 text-white text-xl bold bg-red-600 rounded cursor-pointer"><a style="color:#fff" href="/">{{$t("Back.To.Search")}}</a></button></div>
    </div>
  </div>
</template>