<script>
import { usePartyStore } from "../stores/PartyStore";
export default {
  data() {
    return {
      partyStore: null,
    };
  },
  computed: {
    party() {
      return this.partyStore.party;
    },
  },
  async created() {
    this.partyStore = usePartyStore();
  },
};
</script>

<template>
  <footer
    id="footerContainer"
    class="pt-2 py-4 px-5 md:px-0 mt-0 bg-footer-grey md:justify-between md:items-center"
  >
    <div class="px-0 max-w-5xl mx-auto">
      <div class="md:grid md:grid-cols-3 text-white p-0">
        <div class="grid md:justify-start py-3 md:py-4">
          <a href="https://www.boersenblatt.net/" target="_blank">
          <img
              class="h-5 mb-5"
              src="../assets/Logo_Boersenblatt_RGB_invertiert.png"
              alt="Börsenblatt Logo"
            />
          </a>
          <div>
            <a
              href="https://www.boersenblatt.net/user/register"
              target="_blank"
              class="md:p-0 hover:text-gray-300 text-sm"
            >
              {{ $t("Footer.CreateUserAccount") }}
            </a>
          </div>
          <div>
            <a
              href="https://www.boersenblatt.net/service/abo"
              target="_blank"
              class="md:p-0 hover:text-gray-300 text-sm"
            >
              {{ $t("Footer.BBLPlusService") }}
            </a>
          </div>
          <div>
            <a
              href="https://www.boersenblatt.net/service/newsletter"
              target="_blank"
              class="md:p-0 hover:text-gray-300 text-sm"
            >
              {{ $t("Footer.SubscribeNewsletter") }}
            </a>
          </div>
        </div>
        <div class="grid md:justify-center py-3 md:py-4">
          <img
            class="h-5 mb-5"
            src="../assets/Logo_ADB_RGB_invertiert.png"
            alt="AdB Logo"
          />
          <div>
            <a href="mailto:adressbuch@mvb-online.de" class="md:p-0 hover:text-gray-300 text-sm"
              ><font-awesome-icon icon="fa-solid fa-envelope" />
              adressbuch@mvb-online.de
            </a>
          </div>
          <div>
            <span class="text-sm"
              ><font-awesome-icon icon="fa-solid fa-phone" /> +49 69 1306-550</span
            >
          </div>
        </div>
        <div class="grid md:justify-end py-3 md:py-4">
          <a href="https://medienjobs.boersenblatt.net"
            ><img
              class="h-5 mb-5"
              src="../assets/Logo_medien.jobs_RGB_invertiert.png"
              alt="medien.jobs Logo"
            />
          </a>
          <div>
            <a
              href="https://medienjobs.boersenblatt.net/recruiter"
              target="_blank"
              class="md:p-0 hover:text-gray-300 text-sm"
            >
              {{ $t("Footer.PostJobAd") }}
            </a>
          </div>
          <div>
            <a
              href="https://medienjobs.boersenblatt.net/jobs"
              target="_blank"
              class="md:p-0 hover:text-gray-300 text-sm"
            >
              {{ $t("Footer.SearchJob") }}
            </a>
          </div>
          <div>
            <a
              href="https://medienjobs.boersenblatt.net/unternehmen-suchen"
              target="_blank"
              class="md:p-0 hover:text-gray-300 text-sm"
            >
              {{ $t("Footer.ExploreCompanies") }}
            </a>
          </div>
        </div>
      </div>

      <div class="md:grid md:grid-cols-1 md:gap-1 text-white mt-3">
        {{ $t("Footer.OurServicesForYou") }}
        <div
          class="flex-none lg:flex col-span-full item-center text-sm bg-adb-red p-0 mt-2"
        >
          <div class="p-2 flex-auto">
            <a
              class="hover:text-gray-300 text-xs"
              href="https://mvb-online.de/marken-und-produkte/vlb"
              target="_blank"
              >VLB</a
            >
          </div>
          <div class="p-2 flex-auto">
            <a
              class="hover:text-gray-300 text-xs"
              href="https://mvb-online.de/marken-und-produkte/vlb-tix"
              target="_blank"
              >VLB-TIX</a
            >
          </div>
          <div class="p-2 flex-auto">
            <a
              class="hover:text-gray-300 text-xs"
              href="https://mvb-online.de/marken-und-produkte/isbn"
              target="_blank"
              >ISBN</a
            >
          </div>
          <div class="p-2 flex-auto">
            <a
              class="hover:text-gray-300 text-xs"
              href="https://mvb-online.de/marken-und-produkte/ibu"
              target="_blank"
              >IBU</a
            >
          </div>
          <div class="p-2 flex-auto">
            <a
              class="hover:text-gray-300 text-xs"
              href="https://mvb-online.de/marken-und-produkte/livro"
              target="_blank"
              >Livro</a
            >
          </div>
          <div class="p-2 flex-auto">
            <a
              class="hover:text-gray-300 text-xs"
              href="https://mvb-online.de/marken-und-produkte/buchhandel-de"
              target="_blank"
              >buchhandel.de</a
            >
          </div>
          <div class="p-2 flex-auto">
            <a
              class="hover:text-gray-300 text-xs"
              href="https://mvb-online.de/marken-und-produkte/buchjournal"
              target="_blank"
              >Buchjournal</a
            >
          </div>
          <div class="p-2 flex-auto">
            <a
              class="hover:text-gray-300 text-xs"
              href="https://mvb-online.de/marken-und-produkte/buecherscheck"
              target="_blank"
              >Bücherscheck</a
            >
          </div>
          <div class="p-2 flex-auto">
            <a
              class="hover:text-gray-300 text-center text-xs"
              href="https://mvb-online.de/marken-und-produkte/ada"
              target="_blank"
              >Aus dem Antiquariat</a
            >
          </div>
          <div class="p-2 flex-auto">
            <a
              class="hover:text-gray-300 text-xs"
              href="https://mvb-online.de/marken-und-produkte/metabooks"
              target="_blank"
              >Metabooks</a
            >
          </div>
          <div class="p-2 flex-auto">
            <a
              class="hover:text-gray-300 text-xs"
              href="https://mvb-online.de/marken-und-produkte/pubeasy"
              target="_blank"
              >Pubeasy</a
            >
          </div>
          <div class="p-2 flex-auto">
            <a
              class="hover:text-gray-300 text-xs"
              href="https://mvb-online.de/marken-und-produkte/pubnet"
              target="_blank"
              >Pubnet</a
            >
          </div>
        </div>
      </div>

      <div class="text-xs p-2 md:mt-2 md:flex text-gray-300">
        <div class="md:basis-full">&copy; MVB GmbH {{ new Date().getFullYear() }}</div>

        <div class="md:basis-full">
          <ul class="md:flex-row mt-8 md:float-right md:flex md:items-center md:mt-0">
            <li>
              <router-link to="/sidenav/impressum" class="md:p-2 hover:text-gray-300 text-xs"
                >{{ $t("Footer.Imprint") }}
              </router-link>
            </li>
            <li>
              <a
                href="https://mvb-online.de/agb"
                target="_blank"
                class="md:p-2 hover:text-gray-300 text-xs"
                rel="noopener"
                >{{ $t("Footer.Conditions") }}</a
              >
            </li>
            <li>
              <router-link to="/sidenav/datenschutz" class="md:p-2 hover:text-gray-300 text-xs"
                >{{ $t("Footer.Privacy") }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
