export default {
  "contact1": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An welche Mailadresse kann ich schreiben?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>adressbuch[at]mvb-online.de </p> "])}
  },
  "contact2": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wen kann ich anrufen?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Sie erreichen die ADB-Redaktion unter Telefon: +49 (0)69/1306-550. </p> "])}
  },
  "contact3": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie kann ich Änderungen beantragen?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Per Telefon +49 (0)69/1306-550 oder per Mail: adressbuch[at]mvb-online.de </p> "])}
  },
  "contact4": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie bestelle ich einen neuen Eintrag?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Mit einer Mail an adressbuch[at]mvb-online.de. Die Mail sollte den genauen Namen Ihrer Firma, die Adresse, den Link zu Ihrer Webseite, Kontaktdaten (Telefon, Fax oder Mail), die wichtigsten Kennziffern (Verkehrsnummer, ISBN, GLN, DOI, Umsatzsteuer-ID,VLB- und BAG-Teilnahme) sowie Lieferhinweise für Deutschland, Österreich und Schweiz enthalten. </p> "])}
  },
  "contact5": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo kann ich meine Anregungen, Ideen und Fragen loswerden?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>In einer Mail an adressbuch[at]mvb-online.de </p> "])}
  }
}