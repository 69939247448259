<template>
  <div id="mainContainer" class="bg-white">
    <div class="m-0 p-0">
      <nav class="px-3 pb-2 md:px-2 p-5 bg-gray-100 md:flex md:justify-between md:items-center"
      >
        <!----------------- ADB Logo without Search ------------------>
        <HeaderLogo v-if="party" />

        <!----------------- ADB Logo and Search ------------------>
        <HeaderLogoSearch v-if="!party" />

      </nav>
      <div class="max-w-5xl mx-auto pt-10 px-5 md:px-0">
        <router-view> </router-view>
      </div>
    </div>
    <!-- Footer -->

    <!----------------- sidenavi start --------------------------->
    <div class="px-0 bg-gray-200 mt-3 md:mt-20">

      <div class=" text-center items-center mt-5 md:mt-10 bg-gray-200 max-w-5xl mx-auto border-t md:border-none border-black">
        <ul class="p-0 mt-2 flex-none lg:flex mx-auto w-fit ">
          <li class="p-2 flex-none">
            <a target="_blank" href="https://www.boersenblatt.net/service/anzeigen-buchen/formular-fuer-adb-eintraege" 
              class="underline block p-0 text-sm text-gray-600 hover:text-gray-800 font-bold">
              {{ $t("Navi.Premium-Booking") }}</a>
          </li>    
          <li class="p-2 flex-none">
            <a target="_blank" href="https://www.boersenblatt.net/service/anzeigen-buchen/formular-fuer-aenderungen-von-adb-eintraegen" 
              class="underline block p-0 text-sm text-gray-600 hover:text-gray-800 font-bold">
              {{ $t("Navi.Entry-Change") }}</a>
          </li>              
          <li class="p-2 flex-none">
            <router-link
              :to="{ path: '/sidenav/faq', hash: '#entries' }"
              class="underline block p-0 text-sm text-gray-600 hover:text-gray-800 font-bold"
            >
              {{ $t("Navi.FAQ") }}
            </router-link>
          </li>        
          <li class="p-2 flex-none">
            <router-link
              :to="{ path: '/sidenav/nutzungsbedingungen', hash: '#top' }"
              class="underline block p-0 text-sm text-gray-600 hover:text-gray-800 font-bold"
            >
              {{ $t("Navi.TermsOfUse") }}
            </router-link>
          </li>

        </ul>
      </div>
    </div>
    <!----------------- sidenavi end --------------------------->
    <ShowFooter />
  </div>
</template>

<script>
import HeaderLogoSearch from "./components/HeaderLogoSearch.vue";
import HeaderLogo from "./components/HeaderLogo.vue";
import ShowFooter from "./components/ShowFooter.vue";
import { usePartyStore } from "./stores/PartyStore";

export default {
  name: "App",
  components: { HeaderLogoSearch, HeaderLogo, ShowFooter },
  
  computed: {
    party() {
      return this.partyStore.party;
    },
  },
  async created() {
    this.partyStore = usePartyStore();
  },

  data() {
    return {
       partyStore: null,
       dataReady:false,
    };
    
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
