export default {
  "entries1": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was bringt mir der Eintrag im Adressbuch für den deutschsprachigen Buchhandel?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Mit Ihrem Eintrag auf ADB Online werden Sie als Branchenteilnehmer wahrgenommen und sind sicht- und recherchierbar. Sie können jederzeit Ihre Angaben aktualisieren, indem Sie eine Mail an adressbuch[at]mvb-online.de senden. </p> "])}
  },
  "entries2": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Arten von Einträgen gibt es?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Es gibt zwei Arten von Einträgen: Premium- und Kurzeinträge.</p> "])}
  },
  "entries3": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist der Unterschied zwischen einem Premium- und einem Kurz-Eintrag?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Ein Premium-Eintrag ist viel umfangreicher. Er enthält Adress- und Kommunikationsdaten, aber auch alle wichtigen Kennziffern sowie Lieferhinweise. Er kostet 18 Euro (plus Mehrwertsteuer) für ein Jahr. Kurzeinträge enthalten nur den Namen, die ISBN und die Geschäftsart und sind kostenfrei.</p> "])}
  },
  "entries4": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche zusätzlichen Informationen bietet ein Premiumeintrag?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Ein Premium-Eintrag ist ein Rundum-Sorglos-Paket.<br> Folgende Informationen können dort eingetragen werden:<br> - Geschäftsart<br> - Anschrift mit Straße, Postleitzahl und Ort bzw. Postfach<br> - Internetadresse mit Verlinkung<br> - Kontaktdaten (Telefon, Fax und Mail)<br> - die wichtigsten Kennziffern (Verkehrsnummer, ISBN, GLN, DOI, Umsatzsteuer-ID,VLB- und BAG-Teilnahme)<br> - Lieferhinweise für Deutschland, Österreich und Schweiz<br> - Fachzeitschriftenhinweise zu Preisbindung, Preisbindung Sammelrevers, Vertragsstrafenvereinbarung<br></p> "])}
  },
  "entries5": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie kann ich meinen Kurzeintrag upgraden?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Sie können jederzeit aus einem Kurzeintrag einen Premiumeintrag machen. Eine Mail an adressbuch[at]mvb-online.de genügt.></p> "])}
  }
}