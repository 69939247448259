export default {
  "State.AT-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burgenland"])},
  "State.AT-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kärnten"])},
  "State.AT-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederösterreich"])},
  "State.AT-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oberösterreich"])},
  "State.AT-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salzburg"])},
  "State.AT-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steiermark"])},
  "State.AT-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tirol"])},
  "State.AT-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voralberg"])},
  "State.AT-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wien"])},
  "State.CH-AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aargau"])},
  "State.CH-AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appenzell-Innerrhoden"])},
  "State.CH-AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appenzell-Ausserrohoden"])},
  "State.CH-BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bern"])},
  "State.CH-BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basel-Land"])},
  "State.CH-BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basel"])},
  "State.CH-FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freiburg"])},
  "State.CH-GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genf"])},
  "State.CH-GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glarus"])},
  "State.CH-GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graubünden"])},
  "State.CH-JU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jura"])},
  "State.CH-LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luzern"])},
  "State.CH-NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuenburg"])},
  "State.CH-NW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nidwalden"])},
  "State.CH-OW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obwalden"])},
  "State.CH-SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sankt Gallen"])},
  "State.CH-SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schaffhausen"])},
  "State.CH-SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solothurn"])},
  "State.CH-SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwyz"])},
  "State.CH-TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thurgau"])},
  "State.CH-TI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tessin"])},
  "State.CH-UR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uri"])},
  "State.CH-VD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waadt"])},
  "State.CH-VS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis"])},
  "State.CH-ZG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zug"])},
  "State.CH-ZH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zürich"])},
  "State.DE-BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berlin"])},
  "State.DE-BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brandenburg"])},
  "State.DE-BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baden-Württemberg"])},
  "State.DE-BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bayern"])},
  "State.DE-HB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bremen"])},
  "State.DE-HE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hessen"])},
  "State.DE-HH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamburg"])},
  "State.DE-MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mecklenburg-Vorpommern"])},
  "State.DE-NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedersachsen"])},
  "State.DE-NW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nordrhein-Westfalen"])},
  "State.DE-RP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rheinland-Pfalz"])},
  "State.DE-SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachsen-Anhalt"])},
  "State.DE-SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schleswig-Holstein"])},
  "State.DE-SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saarland"])},
  "State.DE-SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachsen"])},
  "State.DE-TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thüringen"])}
}