export default {
  "search1": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wonach kann ich alles suchen?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Sie können sich Überblicke (alle Buchhandlungen oder alle Verlage) anzeigen lassen oder gezielt nach einzelnen Einträgen suchen. Am effektivsten gelingt das, wenn Sie eine ISBN oder die Verkehrsnummer kennen und diese in das Feld ganz oben für die Schnellsuche eintragen. Sie können aber auch mithilfe einzelner Details suchen. Dazu gehören die Geschäftsart (Buchhandel, Verlag, Zwischenbuchhandel, Dienstleister, Einzel-ISBN-Verlag), der Firmenname, der Ort, das Bundesland, der Kanton und das Land sowie die Kennziffern (GLN, DOI, Umsatzsteuer-IG und BAG). Das angezeigte Suchergebnis kann immer weiter verfeinert werden, indem Sie weitere Details eingeben. Wenn Sie nicht das gewünschte Ergebnis erhalten, klicken Sie bitte – falls er angezeigt wird – auch auf den Link „mehr Suchergebnisse“. </p> "])}
  },
  "search2": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Details kann ich für die Suche nutzen?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Sie haben die Möglichkeit folgende Details einzugeben:<br>  ISBN<br>  Verkehrsnummer<br>  Geschäftsart (Buchhandel, Verlag, Zwischenbuchhandel, Dienstleister und Einzel-ISBN-Verlag)<br>  Menge der aktiven Titel im VLB (nur wenn Sie nach einem Verlag suchen)<br>  Firmenname<br>  Ort, Bundesland oder Kanton und Land<br>  GLN<br>  DOI<br>  Umsatzsteuer-ID<br>  BAG-Teilnahme<br> </p> "])}
  },
  "search3": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worauf sollte ich bei einer ISBN-Suche achten?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Die Suche kann nur dann erfolgreich sein, wenn Sie mindestens 6 Ziffern ins Suchfeld eintragen. Im Idealfall geben Sie eine ISBN-13 ein, dann finden Sie ganz sicher den dazugehörigen Verlag. Mit einer ISBN-10 werden Sie aber auch Erfolg haben. Es ist egal, ob Sie die ISBN mit oder ohne Striche eingeben - beide Suchweisen funktionieren im ADB. </p> "])}
  },
  "search4": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was sollte ich für die Suche nach einer Verkehrsnummer wissen?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Sie können im ADB sowohl debitorische als auch kreditorische Verkehrsnummern suchen. In Deutschland bestehen sie aus 5 Ziffern, in Österreich aus 6 und in der Schweiz aus 7.</p> "])}
  },
  "search5": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was sollte ich bei der DOI-Suche beachten?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Jede DOI beginnt mit 10. Dieser Anfang ist automatisch gesetzt. Um erfolgreich zu suchen, dürfen Sie nicht die komplette DOI eingeben, sondern sollten 10. weglassen. </p> "])}
  },
  "search6": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was sollte ich für die Suche nach einem Firmennamen wissen?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Am erfolgreichsten sind Sie, wenn Sie nur einen Begriff eingeben.  Wenn Sie ein buchhändlerisches Unternehmen mit einem Vor- und Nachnamen suchen wollen, geben Sie am besten zuerst den Nachnamen, dann ein Komma und ein Leerzeichen und dann erst den Vornamen ein. Also besser: König, Walther als Walther König. </p> "])}
  },
  "search7": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was kann ich machen, wenn ich nicht das gewünschte Ergebnis erhalte?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>- Haben Sie schon unter dem Link \"Weitere kürzere Einträge zeigen\" nachgesehen? Wenn ein Kunde nur einen ADB-Kurzeintrag  hat, wird er nicht auf der ersten Seite gelistet, sondern nur unter diesem Link gefunden.<br> - Liefert die Suche nach dem Namen kein Ergebnis, können Sie es auch mit der Geschäftsart und dem Ort versuchen.<br> - Wenn Sie die Verkehrsnummer eines gesuchten Unternehmens kennen und es trotzdem im ADB nicht mit der Verkehrsnummer finden, stellen Sie sicher, dass Sie die Verkehrsnummern-Suche aktiviert haben. Dazu einfach auf den Button \"Verkehrsnummer\" ganz oben rechts klicken. Der graue Button verändert seine Farbe und wird rot. Danach die Verkehrsnummer eingeben. Das Gleiche gilt für die ISBN. Die Suche kann auch hier nur dann erfolgreich sein, wenn der ISBN-Button nicht grau, sondern rot ist.<br> </p> "])}
  },
  "search8": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie sind die Suchergebnisse sortiert?"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mt-5'>Die Ergebnisse sind nach Namen in alphabetischer Reihenfolge sortiert. Wenn der Name gleich ist, werden die Einträge alphabetisch nach Orten gelistet. Wenn ein Unternehmen mit gleichem Namen und gleichen Ort existiert, entscheidet die Straße über die Platzierung - auch hier in alphabetischer Reihenfolge. <br>Wenn Sie nach einer bestimmten ISBN gesucht haben oder nach der Geschäftsart Verlag oder Einzel-ISBN-Verlag, werden die jeweiligen ISBNs aufsteigend sortiert  dargestellt.</p> "])}
  }
}